// polyfills for ie 11
import "./object-assign-polyfil";
import "./object-includes-polyfil";
import "intersection-observer";

import "slick-carousel/slick/slick";
import "slick-carousel/slick/slick.css";
import "../scss/main.scss";
import $ from "jquery";
import Vue from "vue/dist/vue";
import projectFilter from "./vue/project-filter";
import newsFilter from "./vue/news-filter";
import "jquery-match-height";

if(document.getElementById("projects")) {
    Vue.component("project-filter", projectFilter);
    new Vue({
        el: '#projects'
    });
}

if(document.getElementById("news")) {
    Vue.component("news-filter", newsFilter);
    new Vue({
        el: '#news'
    });
}

$(document).ready(function(){
   $(".js-open-nav").click(function () {
        $(".navigation").addClass("navigation--open");
   });
   $(".js-close-nav").click(function () {
        $(".navigation").removeClass("navigation--open");
   });

    if ($(".js-news-slider").length > 0)
    {
        $(".js-news-slider").slick({
            mobileFirst: true,
            arrows: false,
            dots: true,
            responsive: [{
                breakpoint: 1024,
                settings: "unslick"
            }]
        });
    }
    if ($(".home-banner-slider").length > 0)
    {
        $(".home-banner-slider").slick({
            accessibility: true,
            adaptiveHeight: false,
            autoplay: true,
            autoplaySpeed: 4000,
            arrows: true,
            dots: false
        });
    }



    //videoBannerLogic();

    adjustPositionOfSlickDotsForNewsSlider();
    //setupScrollHeader();

    // XL css breakpoint
    if(window.innerWidth >= 1200) {
        setupFixedFooter();
    }
    setupMapToRender();


    $(".js-match-heading-height").matchHeight();
});

function videoBannerLogic() {
    const video = document.getElementById("video-banner");
    const videoCopy = document.querySelector(".js-video-copy");
    /*
    video.addEventListener("loadeddata", function () {
        console.log("video has loaded");
        video.play();
        videoCopy.classList.add("is-active");
    });
    */
    document.querySelector(".js-pause-video").addEventListener("click", function () {
        video.pause();
        videoCopy.classList.remove("is-active");
    });
    document.querySelector(".js-play-video").addEventListener("click", function () {
        video.play();
        videoCopy.classList.add("is-active");
    });
}

function setupMapToRender(){
    $(".js-map").each(function(){
        const long = $(this).attr("data-long");
        const lat = $(this).attr("data-lat");
        const id = $(this).attr("id");
        renderMap(id, long, lat);
    })
}

function renderMap(container, long, lat){
    mapboxgl.accessToken = 'pk.eyJ1IjoiZGV2ZWxvcGVycy1tY21uZXQiLCJhIjoiY2s4NzVycjF1MDYzMzNsb2kwcmVwNjF2NiJ9.673rLme4fRdhhqeaXpGWrg';
    const map = new mapboxgl.Map({
        container: container,
        style: 'mapbox://styles/mapbox/streets-v11', // stylesheet location
        center: [long, lat], // starting position [lng, lat] 51.2702617,0.1869976
        zoom: 13 // starting zoom
    });
    const marker = new mapboxgl.Marker()
        .setLngLat([long, lat])
        .addTo(map);

    map.on("click",
        function (e) {
            // Todo make url dynamic and pulled from cms
            window.open("https://www.google.co.uk/maps/place/TN13%201EB");
        });
}

function setupFixedFooter(){
   const heightOfFooter = $(".js-fixed-footer").height();
   $(".js-body-wrapper").css("margin-bottom", heightOfFooter);
}

$(window).resize(function (){
   adjustPositionOfSlickDotsForNewsSlider();
});

function adjustPositionOfSlickDotsForNewsSlider(){
    const slickListWidth = $(".js-news-slider .slick-list").outerWidth();
    // size of slick list at 320 and pixels of dots from top 190px that fit well at 320px width
    const ratio = 190 / 272;
    $(".js-news-slider .slick-dots").css("top", slickListWidth * ratio);
}

function setupScrollHeader(){
    let isLeaving = false;
    let observer = new IntersectionObserver(entries => {
        $.each(entries, (index,entry) => {
            if (entry.isIntersecting) {
                // we are ENTERING the "capturing frame". Set the flag.
                isLeaving = true;
                // Do something with entering entry
                $(".js-scroll-header").removeClass("header--is-fixed");
            } else if (isLeaving) {
                // we are EXITING the "capturing frame"
                isLeaving = false;
                // Do something with exiting entry
                $(".js-scroll-header").addClass("header--is-fixed");
            }
            // Here we can do something with each particular entry
        });
    });
    let target = document.querySelector('.js-scroll-header-trigger');
    observer.observe(target);
}