<template>
    <div>
    <!-- project filter -->
    <div class="container-md p-0 px-md-4">
        <div class="d-none d-md-block text-center my-16">
            <p class="text-carGrey m-0 font-size-1-1">FILTER BY</p>
            <nav class="filter-pill">
                <button v-bind:class="getButtonClassForProjectsWithImages(selectedFilterProjectWithImages, '')" v-on:click="handleFilterProjectsWithImages('')">All</button>
                <button v-bind:class="getButtonClassForProjectsWithImages(selectedFilterProjectWithImages, filter.Id)" v-for="filter in data.Filters" v-on:click="handleFilterProjectsWithImages(filter.Id)">{{filter.Name}}</button>
            </nav>
        </div>
        <nav class="d-md-none text-center p-8">
            <p class="text-carGrey">FILTER BY</p>
            <select class="form-control border" v-on:change="handleSelectBoxFilterProjectsWithImages">
                <option value="">All</option>
                <option v-for="filter in data.Filters" v-bind:value="filter.Id">{{filter.Name}}</option>
            </select>
        </nav>

        <section class="row post-preview-grid">
            <article v-for="post in ProjectWithImagesFiltered.slice(0, ProjectWithImagesLazyLoadIndex)" class="col-lg-8 col-md-12 post-preview-grid__item">
                <div class="ratio ratio--418-343">
                    <div class="ratio__inner">
                        <a class="post-preview" v-bind:href="post.Url">
                            <figure class="post-preview__image bg-image-cover" v-bind:style="'background-image: url('+ post.ImageUrl +')'"></figure>
                            <div class="post-preview__content">
                                <template v-for="text,index in post.Caption">
                                    <p v-if="index != 1">{{text}}</p>
                                    <h4 v-if="index == 1">{{text}}</h4>
                                </template>
                            </div>
                        </a>
                    </div>
                </div>
            </article>
        </section>

        <div v-if="ProjectWithImagesLazyLoadIndex < ProjectWithImagesFiltered.length" class="text-center mt-12 mb-10">
            <button class="btn btn-primary" v-on:click="loadMoreProjectsWithImages">LOAD MORE</button>
        </div>

        <div class="p-8 text-md-center">
            <h4 class="mb-md-10">FULL PROJECT LIST</h4>
            <hr class="line-break mb-md-4" />
        </div>
    </div>
    <!-- end project filter -->
    <div>
        <div class="bg-marine-grey py-md-9">
            <div class="d-none d-md-block text-center">
                <p class="text-carGrey m-0 font-size-1-1">FILTER BY</p>
                <nav class="filter-pill">
                    <button v-bind:class="getButtonClassForProjectsWithoutImages(selectedFilterProjectWithoutImages, '')" v-on:click="handleFilterProjectsWithOutImages('')">All</button>
                    <button v-bind:class="getButtonClassForProjectsWithoutImages(selectedFilterProjectWithoutImages, filter.Id)" v-for="filter in data.Filters" v-on:click="handleFilterProjectsWithOutImages(filter.Id)">{{filter.Name}}</button>
                    <div class="filter-pill__bar"></div>
                </nav>
            </div>
            <nav class="d-md-none text-center p-8">
                <p class="text-carGrey">FILTER BY</p>
                <select class="form-control" v-on:change="handleSelectBoxFilterProjectsWithOutImages">
                    <option value="">All</option>
                    <option v-for="filter in data.Filters" v-bind:value="filter.Id">{{filter.Name}}</option>
                </select>
            </nav>
        </div>
        <section class="container pt-8 pb-12">
            <div class="job-preview pt-8 pb-6 pt-md-12 pb-md-8" v-for="post in ProjectWithoutImagesFiltered.slice(0, ProjectWithoutImagesLazyLoadIndex)">
                <template v-for="text,index in post.Caption">
                    <address class="ts-bold job-preview__line job-preview__line--first" v-if="index == 0">{{text}}</address>
                    <address class="job-preview__line" v-if="index == 1">{{text}}</address>
                    <p class="job-preview__line" v-if="index == 2">{{text}}</p>
                    <time class="ts-bold pb-4 d-block job-preview__line job-preview__line--last" v-if="index == 3">{{text}}</time>
                </template>
            </div>

            <div v-if="ProjectWithoutImagesLazyLoadIndex < ProjectWithoutImagesFiltered.length" class="text-center mt-12">
                <button class="btn btn-primary" v-on:click="loadMoreProjectsWithoutImages">LOAD MORE</button>
            </div>
        </section>
    </div>
    </div>
</template>

<script>
    import $ from "jquery";
    import _ from "lodash";
    export default {
        name: "project-filter",
        methods:{
            getButtonClassForProjectsWithImages(selectedfilter, filterId){
               if(selectedfilter == filterId){
                   return "btn font-size-1-4 text-uppercase js-filter-with-images-btn btn--is-active";
               }else{
                   return "btn font-size-1-4 text-uppercase js-filter-with-images-btn"
               }
            },
            getButtonClassForProjectsWithoutImages(selectedfilter, filterId) {
                if (selectedfilter == filterId) {
                    return "btn font-size-1-4 text-uppercase js-filter-without-images-btn btn--is-active";
                } else {
                    return "btn font-size-1-4 text-uppercase js-filter-without-images-btn"
                }
            },
            loadMoreProjectsWithoutImages(){
                this.ProjectWithoutImagesLazyLoadIndex += 12;
            },
            loadMoreProjectsWithImages(){
                this.ProjectWithImagesLazyLoadIndex += 12;
            },
            handleSelectBoxFilterProjectsWithImages(event){
                this.handleFilterProjectsWithImages(event.target.value);
            },
            handleSelectBoxFilterProjectsWithOutImages(event){
                console.log(event.target.value);
                this.handleFilterProjectsWithOutImages(event.target.value);
            },
            handleFilterProjectsWithImages(value){

                this.selectedFilterProjectWithImages = value;
                if(value == ""){
                    this.ProjectWithImagesFiltered = _.cloneDeep(this.data.ProjectWithImages);
                }else {
                    this.ProjectWithImagesFiltered = this.filterArrayById(_.cloneDeep(this.data.ProjectWithImages), value);
                }
            },
            handleFilterProjectsWithOutImages(value){
                this.selectedFilterProjectWithoutImages = value;
                if(value == ""){
                    this.ProjectWithoutImagesFiltered = _.cloneDeep(this.data.ProjectWithoutImages);
                }else {
                    this.ProjectWithoutImagesFiltered = this.filterArrayById(_.cloneDeep(this.data.ProjectWithoutImages), value);
                }
            },
            filterArrayById(array, Id){
                return array.filter(x => x.Categories.indexOf(Id) >= 0);
            }
        },
        mounted() {
            $.ajax({
                type: "GET",
                url: "/umbraco/surface/jsonapi/GetProjectPageFeed"
            }).done((data)=>{
                this.data = data;
                this.ProjectWithoutImagesFiltered = data.ProjectWithoutImages;
                this.ProjectWithImagesFiltered = data.ProjectWithImages;
            });
        },
        data(){
            return {
                ProjectWithImagesLazyLoadIndex: 12,
                ProjectWithoutImagesLazyLoadIndex: 12,
                ProjectWithImagesFiltered:[],
                ProjectWithoutImagesFiltered: [],
                selectedFilterProjectWithImages: "",
                selectedFilterProjectWithoutImages: "",
                data:{
                    Filters:[],
                    ProjectWithImages:[],
                    ProjectWithoutImages:[]
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    
</style>