<template>
    <div class="container-lg p-0">
        <div class="row no-gutters">
            <div class="col-lg-8 px-lg-3 pb-lg-6" v-for="post in data.slice(0, count)">
                <a v-bind:href="post.Url" class="link-hover-effect-1">
                    <figure class="m-0 d-flex flex-column d-lg-block">
                        <img v-bind:src="post.ImageUrl" class="img-fluid w-100"/>
                        <figcaption class="p-6 text-md-center bg-marine-grey">
                            <time class="text-spaceGrey font-size-1-1 font-size-md-1-3 mb-4 d-block">{{post.Date}}</time>
                            <h5>{{post.Heading}}</h5>
                            <div class="d-md-none">
                                <hr class="line-break mb-4 mt-4" />
                                <div v-html="post.Copy" />
                            </div>
                        </figcaption>
                    </figure>
                </a>
            </div>
        </div>
        <div class="text-center">
            <button class="btn btn-primary" v-on:click="loadMore">LOAD MORE</button>
        </div>
    </div>
</template>

<script>
    import $ from "jquery";

    export default {
        name: "news-filter",
        mounted() {
            $.ajax({
                type: "GET",
                url: "/umbraco/surface/jsonapi/GetNewsPageFeed"
            }).done((data)=>{
                this.data = data;
            });
        },
        methods: {
            loadMore() {
                this.count += 12;
            }
        },
        data(){
            return {
                data: [],
                count: 12
            }
        }
    }
</script>

<style scoped>

</style>